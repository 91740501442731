"use client";

import { useDispatch, useSelector } from 'react-redux';
import {
  selectGuideposts,
  selectTrackerUnit,
  selectTrackerName,
  selectTrackerData, selectShowTracker, selectScheduleEvents, selectShowSchedule
} from "../redux/selectors/roadmapV2.ts";
import {
  updateGuideposts,
  updateTrackerData,
  updateTrackerName,
  updateTrackerUnit,
  clearTrackerData,
  clearChecks,
  clearPractices,
  updateShowTracker, updateShowSchedule,
} from "../redux/reducers/roadmapV2Slice.ts";
import {TrackerItem} from "../models/Tracker";


const usePlannerState = () => {
  const dispatch = useDispatch();

  const trackerData = useSelector(selectTrackerData);
  const guidePostData = useSelector(selectGuideposts);
  const trackerUnit = useSelector(selectTrackerUnit);
  const trackerName = useSelector(selectTrackerName);
  const showTracker = useSelector(selectShowTracker)
  const eventData = useSelector(selectScheduleEvents)
  const showSchedule = useSelector(selectShowSchedule)

  const setTrackerData = (pTrackerData: TrackerItem[]): void => {
    dispatch(updateTrackerData( pTrackerData ));
  };

  const setGuidepostData = (pGuideposts: TrackerItem[]): void => {
    dispatch(updateGuideposts(pGuideposts));
  };

  const setTrackerName = (pName: string): void => {
    dispatch(updateTrackerName(pName));
  };

  const setTrackerUnit = (pUnit: string): void => {
    dispatch(updateTrackerUnit(pUnit));
  };

  const setShowTracker = (pShow: boolean): void => {
    dispatch(updateShowTracker(pShow));
  };


  const setShowSchedule = (pShow: boolean): void => {
    dispatch(updateShowSchedule(pShow));
  };


  const setTrackerDataEmpty = () => {
    dispatch(clearTrackerData());
  }

  const setCheckDataEmpty = () => {
    dispatch(clearChecks());
  }

  const setPracticesEmpty = () => {
    dispatch(clearPractices());
  }

  return {
    trackerData,
    guidePostData,
    trackerUnit,
    trackerName,
    showTracker,
    eventData,
    showSchedule,
    setShowSchedule,
    setShowTracker,
    setTrackerData,
    setGuidepostData,
    setTrackerName,
    setTrackerUnit,
    setTrackerDataEmpty,
    setCheckDataEmpty,
    setPracticesEmpty,
  };
};

export default usePlannerState;
