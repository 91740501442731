import React from 'react';
import styled from 'styled-components';
import { themeColor } from "../../TWConstants.ts";
import { blendColors } from '../../utilities/OTColors';
import { ScheduleEvent } from "../../models/Schedule.ts";

const START_HOUR = 7;
const END_HOUR = 24;
const TOTAL_HOURS = END_HOUR - START_HOUR;

const EventContainer = styled.div<{ left: string; width: string }>`
  position: absolute;
  left: ${props => props.left};
  width: ${props => props.width};
  height: 40px;
  top: 20px;
  background-color: ${blendColors(themeColor, '#ffffff', 0.65)};
  border: 2px solid ${themeColor};
  border-radius: 6px;
  display: flex;
  align-items: start;
  justify-content: stretch;
  backdrop-filter: blur(4px);
  font-family: Avenir, sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
      background-color: ${blendColors(themeColor, '#ffffff', 0.35)};
    transform: translateY(-2px);
  }
`;

const EventTitle = styled.div`
  color: ${themeColor};
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;    
  text-overflow: ellipsis;
`;


const parseTime = (timeStr: string): { hours: number; minutes: number } => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return { hours, minutes };
};

const getHourDecimal = (timeStr: string): number => {
  const { hours, minutes } = parseTime(timeStr);
  return hours + (minutes / 60);
};

const calculatePosition = (timeStr: string): string => {
  const hours = getHourDecimal(timeStr);
  const hoursFromStart = hours - START_HOUR;
  return `${(hoursFromStart / TOTAL_HOURS) * 100}%`;
};

const calculateWidth = (event: ScheduleEvent): string => {
  const duration = getHourDecimal(event.endTime) - getHourDecimal(event.startTime);
  return `calc(${(duration / TOTAL_HOURS) * 100}% - 4px)`;
};

interface OTScheduleEventProps {
  event: ScheduleEvent;
  onClick: () => void;
}

const OTScheduleEvent = ({ event, onClick }: OTScheduleEventProps) => {
  const left = calculatePosition(event.startTime);
  const width = calculateWidth(event);

  return (
    <EventContainer
      left={left}
      width={width}
      onClick={onClick}
    >
      <EventTitle>{event.title}</EventTitle>
    </EventContainer>
  );
};

export default OTScheduleEvent;
