import { RoadmapV2Slice} from "../reducers/roadmapV2Slice";
import { createSelector } from "reselect";
import { RootState } from "../store";

// RootState is the type of your Redux store's state
export const selectRoadmapV2State = (state: RootState) => state.roadmapV2;

export const selectPractices = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.practices
);

export const selectScheduleEvents = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.scheduleEvents
);

export const selectTrackerData = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.trackerData
);

export const selectGuideposts = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.guideposts
);

export const selectTrackerName = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.trackerName
);

export const selectTrackerUnit = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.trackerUnit
);

export const selectShowTracker = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.showTracker
);

export const selectShowSchedule = createSelector(
  [selectRoadmapV2State],
  (roadmapV2State: RoadmapV2Slice) => roadmapV2State.showSchedule
);
