import React, {useState, ChangeEvent} from "react";
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import {themeColor} from "../../TWConstants.ts";
import {nanoid} from 'nanoid';
import { useDispatch } from 'react-redux';
import {addScheduleEvent, deleteScheduleEvent, updateScheduleEvent} from "../../redux/reducers/roadmapV2Slice.ts";
import {ScheduleEvent} from "../../models/Schedule";


const Curtain = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  font-family: Avenir, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:2;
`;

const Modal = styled.div`
  width: 460px;
  padding: 15px;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
`;

const InputLabel = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
`;

const Input = styled.input`
  height: 24px;
  width: calc(100% - 12px);
  font-family: Avenir, sans-serif;
  font-size: 16px;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.1);
`;

const InputSection = styled.div`
  margin: 14px 0;
  width: 100%;
`;

const SaveButton = styled.button`
  background-color: ${themeColor};
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-family: Avenir, sans-serif;
  padding: 10px 15px;
  flex: 0;
    min-width: 120px;
`;

const DeleteButton = styled(SaveButton)`
    background-color: #600606;
`

const ErrorMessage = styled.div<{isVisible: boolean}>`
  font-size: 16px;
  color: white;
  text-align: center;
  background-color: #b30030;
  padding: 6px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: ${({isVisible}) => isVisible ? 1 : 0};
`;

const ButtonHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

export interface AddEditScheduleEventModalProps {
  closeModal: ()=>void;
  event?: ScheduleEvent;
}

const AddEditScheduleEventModal = (
  {
    closeModal,
    event
  }: AddEditScheduleEventModalProps,
) => {


  const dispatch = useDispatch();
  const [eventName, setEventName] = useState(event ? event.title : '')
  const [eventEndTime, setEventEndTime] = useState(event ? event.endTime : '')
  const [eventStartTime, setEventStartTime] = useState(event ? event.startTime : '')

  const [error, setError] = useState("empty")

  const hideErrorDelayed = () => {
    setTimeout(() => {
      setError("empty")
    }, 3000)
  }

  const isFormValid = ():boolean => {

    if (eventName.length <= 0) {
      setError("Please enter an event name")
      hideErrorDelayed()
      return false;
    }

    if (eventStartTime.length <= 0) {
      setError("Please enter an event end time")
      hideErrorDelayed()
      return false;
    }

    if (eventEndTime.length <= 0) {
      setError("Please enter an event start time")
      hideErrorDelayed()
      return false;
    }

    return true
  }

  const doAddEvent = () => {
    if (!isFormValid()) {
      return;
    }

    const newEvent:ScheduleEvent = {
      title: eventName,
      startTime: eventStartTime,
      id: nanoid(),
      endTime: eventEndTime,
    }
    dispatch(addScheduleEvent(newEvent))
    closeModal();
  }

  const doUpdateEvent = (pEvent:ScheduleEvent) => {
    if (!isFormValid()) {
      return;
    }

    const updatedEvent:ScheduleEvent = {
      title: eventName,
      startTime: eventStartTime,
      id: pEvent.id,
      endTime: eventEndTime,
    }
    dispatch(updateScheduleEvent(updatedEvent))
    closeModal();
  }

  const doDeleteEvent = (pEvent:ScheduleEvent) => {
    dispatch(deleteScheduleEvent(pEvent.id))
    closeModal();
  }

  return (
    <Curtain>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeModal();
        }}
      >
        <Modal>
          <Title>
            { event ? 'Edit Event' : 'Add Event'}
          </Title>
          <InputSection>
            <InputLabel>Name</InputLabel>
            <Input
              placeholder="Enter event name (e.g. Workout)"
              value={eventName}
              onChange={(e:ChangeEvent<HTMLInputElement>) => {
                setEventName(e.target.value);
              }}
            />
          </InputSection>
          <InputSection>
            <InputLabel>Start Time</InputLabel>
            <Input
              placeholder="Enter event start time (e.g. 02:45)"
              value={eventStartTime}
              onChange={(e:ChangeEvent<HTMLInputElement>) => {
                setEventStartTime(e.target.value);
              }}
            />
          </InputSection>
          <InputSection>
            <InputLabel>End Time</InputLabel>
            <Input
              placeholder="Enter event end time (e.g. 04:55)"
              value={eventEndTime}
              onChange={(e:ChangeEvent<HTMLInputElement>) => {
                setEventEndTime(e.target.value);
              }}
            />
          </InputSection>
          <ErrorMessage isVisible={error !== "empty"}>
            {error}
          </ErrorMessage>
          <ButtonHolder>
            <SaveButton onClick={() => {
              if (!event) {
                doAddEvent()
              } else {
                doUpdateEvent(event)
              }
            }}>
              {event ? 'Save Event' : 'Add Event'}
            </SaveButton>
            { event &&
		          <DeleteButton onClick={()=> {
                if(window.confirm('Are you sure you want to delete this event?')){
                  doDeleteEvent(event);
                }
              }}>Delete</DeleteButton>
            }
          </ButtonHolder>

        </Modal>
      </OutsideClickHandler>
    </Curtain>
  );
};

export default AddEditScheduleEventModal;
