import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {themeColor} from "../../TWConstants.ts";
import { hexColorWithOpacity } from '../../utilities/OTColors';
import {ScheduleEvent, ScheduleTimeSlot} from "../../models/Schedule.ts";
import OTEventContainer from "./OTEventContainer.tsx";
import usePlannerState from "../../hooks/usePlannerState.ts";


// Constants
const START_HOUR = 7;
const END_HOUR = 24;
const TOTAL_HOURS = END_HOUR - START_HOUR;

// Styled Components
const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: start;
  justify-content: center;
  gap: 6px;
  font-family: Avenir, sans-serif;
`;

const DailyScheduleLabel = styled.div`
    padding: 8px 15px;
    background-color: ${themeColor};
    color: white;
  font-weight: bold;
    max-width: 218px;
  border-bottom-right-radius: 10px;
`


const ScheduleContainer = styled.div`
  position: relative;
  width: 1076px;
  min-width: 1076px;
  height: 160px;
  background: rgba(255,255,255,0.7);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border: 3px solid ${themeColor};
  backdrop-filter: blur(3px);
`;

const TimelineContainer = styled.div`
  position: relative;
  height: 80px;
  margin: 10px 20px 28px 20px;
  display: flex;
  align-items: center;
`;


const TimeLabel = styled.span<{ left: string }>`
  position: absolute;
  left: ${props => props.left};
  bottom: -20px;
  transform: translateX(-50%);
  font-size: 12px;
  color: ${themeColor};
`;

const GridLine = styled.div<{ left: string }>`
  position: absolute;
  left: ${props => props.left};
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: ${hexColorWithOpacity(themeColor, 0.5)};
  transform: translateX(-50%);
  border-radius: 4px;
`;

const Timeline = styled.div`
  position: relative;
  height: 2px;
  background-color: ${hexColorWithOpacity(themeColor, 0.5)};
  width: 100%;
    border-radius: 4px;
`;

const AddEventButton = styled.button`
    background-color: ${themeColor};
    color: white;
    font-weight: bold;
    height: 26px;
    width: 26px;
    right: 12px;
    top: 10px;
    border: none;
    position: absolute;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-left: 8px;
    cursor: pointer;
`

const CurrentTimeIndicator = styled.div<{ left: string }>`
  position: absolute;
  left: ${props => props.left};
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: ${themeColor};
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 2px;
  
  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 10px;
    height: 10px;
    background-color: ${themeColor};
    border-radius: 50%;
  }
`;

interface OTDailyScheduleProps {
  addEvent: () =>void;
  editEvent:(e:ScheduleEvent)=>void;
}

const OTDailySchedule  = (
  {addEvent, editEvent}:OTDailyScheduleProps
) => {

  const {eventData} = usePlannerState()

  const generateTimeSlots = (): ScheduleTimeSlot[] => {
    return Array.from({ length: TOTAL_HOURS + 1 }, (_, index) => ({
      hour: START_HOUR + index
    }));
  };

  const formatTime = (hour: number): string => {
    if (hour === 24) return '12AM';
    const period = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 || 12;
    return `${displayHour}${period}`;
  };

  const calculateLeftPosition = (hour: number): string => {
    const hoursFromStart = hour - START_HOUR;
    return `${(hoursFromStart / TOTAL_HOURS) * 100}%`;
  };

  const timeSlots = generateTimeSlots();

  const [currentTimePosition, setCurrentTimePosition] = useState<string>("0%");

  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const currentHour = now.getHours() + now.getMinutes() / 60;

      if (currentHour >= START_HOUR && currentHour <= END_HOUR) {
        const hoursFromStart = currentHour - START_HOUR;
        const position = `${(hoursFromStart / TOTAL_HOURS) * 100}%`;
        setCurrentTimePosition(position);
      }
    };

    // Update immediately
    updateCurrentTime();

    // Update every minute
    const interval = setInterval(updateCurrentTime, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <ScheduleContainer>
        <DailyScheduleLabel>
          Daily Schedule
        </DailyScheduleLabel>
        <AddEventButton onClick={() => {
          addEvent();
        }}>
          +
        </AddEventButton>
        <TimelineContainer>
          <Timeline/>
          {timeSlots.map((slot) => {
            const leftPosition = calculateLeftPosition(slot.hour);
            return (
              <Fragment key={`time-${slot.hour}`}>
                <TimeLabel left={leftPosition}>
                  {formatTime(slot.hour)}
                </TimeLabel>
                <GridLine left={leftPosition} />
              </Fragment>
            );
          })}
          <CurrentTimeIndicator left={currentTimePosition} />
          {eventData.map((event) => {
            return (<OTEventContainer event={event} onClick={() => editEvent(event)}/>)
          })}
        </TimelineContainer>
      </ScheduleContainer>
    </Wrapper>
  );
};

export default OTDailySchedule;
